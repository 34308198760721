.section {
  position: relative;
  width: 100%;
  background: #fff;
}
.section .inner {
  height: inherit;
  box-sizing: border-box;
  padding: 5rem 3.5rem 6rem;
}
.section .inner .wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 111rem;
  height: 100%;
  margin: 0 auto;
}
.section .inner .wrap h2 {
  font-size: 34px;
  color: #101017;
  text-align: center;
  margin-bottom: 60px;
}
.section .inner .wrap .aboutList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
}
.section .inner .wrap .aboutList li {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  position: relative;
  margin-bottom: 60px;
  padding: 18px;
}
.section .inner .wrap .aboutList li:last-child {
  margin-bottom: 0;
}
.section .inner .wrap .aboutList li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: radial-gradient(50.01% 50% at 50.01% 50.03%, #D7D7D7 0%, #FFFFFF 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.section .inner .wrap .aboutList li:nth-child(3)::after,
.section .inner .wrap .aboutList li:last-child::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: radial-gradient(50.01% 50% at 50.01% 50.03%, #D7D7D7 0%, #FFFFFF 100%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.section .inner .wrap .aboutList li:nth-last-child(3) {
  margin-bottom: 0;
}
.section .inner .wrap .aboutList li:nth-last-child(2) {
  margin-bottom: 0;
}
.section .inner .wrap .aboutList li .picture {
  margin-bottom: 40px;
}
.section .inner .wrap .aboutList li p {
  text-align: center;
}
@media (max-width: 1150px) {
  .section .inner .wrap .aboutList li {
    width: 40%;
  }
  .section .inner .wrap .aboutList li::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background: radial-gradient(50.01% 50% at 50.01% 50.03%, #D7D7D7 0%, #FFFFFF 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .section .inner .wrap .aboutList li:nth-last-child(3) {
    margin-bottom: 60px;
  }
}
@media (max-width: 800px) {
  .section .inner .wrap h2 {
    font-size: 22px;
  }
  .section .inner .wrap .aboutList {
    flex-direction: column;
    align-items: center;
  }
  .section .inner .wrap .aboutList li {
    width: 70%;
  }
  .section .inner .wrap .aboutList li::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background: radial-gradient(50.01% 50% at 50.01% 50.03%, #D7D7D7 0%, #FFFFFF 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
  .section .inner .wrap .aboutList li:nth-last-child(2) {
    margin-bottom: 60px;
  }
}
@media (max-width: 420px) {
  .section .inner .wrap h2 {
    margin-bottom: 40px;
  }
  .section .inner .wrap .aboutList li {
    margin-bottom: 20px !important;
  }
  .section .inner .wrap .aboutList li:last-child {
    margin-bottom: 0 !important;
  }
}
