.header {
    position: relative;
    width: 100%;
    height: 100vh;
    background: linear-gradient(100.28deg, #1476BB 12.13%, #4967AE 77.75%);

    .inner {
        height: inherit;
        box-sizing: border-box;
        padding: 3rem 3.5rem;

        .wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            max-width: 111rem;
            height: 100%;
            margin: 0 auto;
            text-align: center;

            .topSide {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 50px;
                z-index: 9;

                .logo {
                    width: 211px;
                    height: 46px;
                    background: url('../../../static/images/logo/11q2.png') no-repeat;
                }

                .menu {
                    display: flex;
                    flex-direction: row;
                    list-style: none;

                    li {
                        margin-right: 20px;
                        line-height: 2.5;

                        &:last-child {
                            margin-right: 0;
                        }

                        a {
                            font-size: 16px;
                            color: #fff;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        .register {
                            background: #67B550;
                            border-radius: 5px;
                            padding: 10px 16px;
                        }
                    }
                }

                .burger {
                    display: none;
                    width: 40px;
                    height: 40px;
                    background-image: url('../../../static/images/burger2.svg');
                    background-repeat: no-repeat;
                    background-size: 40px 40px;

                    &.close {
                        background-image: url('../../../static/images/close.svg');
                    }

                    &:hover {
                        cursor: pointer;
                        opacity: .8;
                    }
                }

                @media (max-width: 850px) {}
            }

            .bottomSide {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                margin-bottom: 80px;

                .leftSide {
                    position: relative;
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    margin-top: -35px;

                    h1,
                    h2 {
                        color: #fff;
                        font-family: 'Montserrat', Verdana, sans-serif;
                        font-weight: 700;
                    }

                    h1 {
                        font-size: 28px;
                        width: 555px;
                        margin-bottom: 20px;
                        line-height: 1.2;
                    }

                    h2 {
                        display: inline;
                        text-align: left;
                        height: auto !important;
                        // font-size: 40px;
                        font-size: 24px !important;
                        background: #FFFFFF;
                        color: #1D7ABE;
                        padding: 5px 12px;
                        border-radius: 8px;
                        width: max-content;
                        z-index: 1;
                    }

                    h2.fillTitle {
                        background: #fff;
                        color: #1D7ABE;
                        padding: 0 6px 3px;
                        border-radius: 8px;
                        height: auto;
                    }

                    .slider {
                        display: block;
                        background-color: #3E79C1;
                        padding: 5px 13px;
                        border-radius: 8px;
                        margin: 30px 0 70px;
                        width: 458px;

                        .item {
                            color: #fff;
                            font-size: 21px;

                            p {
                                margin: 0;
                            }
                        }
                    }

                    p {
                        text-align: left;
                        color: #fff;
                        margin: 100px 0 80px;
                        line-height: 1.6;
                        letter-spacing: 0.04px;
                        font-size: 16px;
                    }

                    .buttonList {
                        display: flex;
                        flex-direction: row;
                        list-style: none;

                        li {
                            width: 214px;
                            margin-right: 20px;

                            &:last-child {
                                width: 290px;
                                margin-right: 0;

                                a {
                                    border-color: #FFFFFF;
                                    background: none;

                                    &:hover {
                                        background: #62BB50;
                                    }
                                }
                            }

                            a {
                                font-size: 14px;
                                color: #fff;
                                background: #62BB50;
                                border-radius: 100px;
                                box-sizing: border-box;
                                padding: 14px 22px;
                                border: 2px solid #62BB50;

                                &:hover {
                                    border: 2px solid #FFFFFF;
                                }
                            }
                        }
                    }
                }

                .rightSide {
                    width: 50%;
                    position: relative;
                    text-align: right;

                    img {
                        position: relative;
                        margin-top: -134px;
                    }

                    .buttonList {
                        display: none;
                        flex-direction: column;
                        align-items: center;
                        list-style: none;
                        margin: 20px 0;
                        text-align: center;

                        li {
                            width: 260px;
                            margin-top: 48px;

                            &:last-child {
                                a {
                                    border-color: #FFFFFF;
                                    background: none;

                                    &:hover {
                                        background: #62BB50;
                                    }
                                }
                            }

                            a {
                                font-size: 14px;
                                color: #fff;
                                background: #62BB50;
                                border-radius: 100px;
                                box-sizing: border-box;
                                padding: 12px 32px;
                                border: 2px solid #62BB50;

                                &:hover {
                                    border: 2px solid #FFFFFF;
                                }
                            }
                        }
                    }

                    .owl {
                        position: absolute;
                        top: 20.4%;
                        left: 30.4%;
                    }

                    .robot {
                        position: absolute;
                        bottom: 12%;
                        right: 27%;
                    }
                }
            }

            .sticky {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 60px;
                transform: translateZ(0px);
                background: linear-gradient(100.28deg, #1476BB 12.13%, #4967AE 77.75%);
                z-index: 999999;

                .inner {
                    height: inherit;
                    box-sizing: border-box;
                    padding: 1rem 3.5rem;

                    .wrap {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        position: relative;
                        max-width: 111rem;
                        height: 100%;
                        margin: 0 auto;
                        text-align: center;

                        .topSide {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                            height: 50px;

                            .logo {
                                width: 201px;
                                height: 36px;
                                background-image: url('../../../static/images/logo/11q2.png');
                                background-repeat: no-repeat;
                                background-size: 201px 36px;
                            }

                            .menu {
                                display: flex;
                                flex-direction: row;
                                list-style: none;

                                li {
                                    margin-right: 20px;
                                    line-height: 2.5;

                                    &:last-child {
                                        margin-right: 0;
                                    }

                                    a {
                                        font-size: 16px;
                                        color: #fff;

                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    }

                                    .register {
                                        background: #67B550;
                                        border-radius: 5px;
                                        padding: 8px 12px;
                                    }
                                }
                            }

                            .burger {
                                display: none;
                                width: 40px;
                                height: 40px;
                                background-image: url('../../../static/images/burger2.svg');
                                background-repeat: no-repeat;
                                background-size: 40px 40px;

                                &.close {
                                    background-image: url('../../../static/images/close.svg');
                                }

                                &:hover {
                                    cursor: pointer;
                                    opacity: .8;
                                }
                            }

                            // @media (max-width: 1180px) {
                            //     .menu {
                            //         li:last-child {
                            //             display: none;
                            //         }
                            //         li:nth-last-child(2) {
                            //             margin-right: 0;
                            //         }
                            //     }
                            // }

                            @media (max-width: 988px) {
                                .logo {
                                    width: 165px;
                                    height: 36px;
                                    background-size: 165px 36px;
                                }

                                .menu {
                                    li {
                                        margin-right: 14px;

                                        a {
                                            font-size: 15px;
                                        }
                                    }
                                }
                            }

                            @media (max-width: 850px) {
                                .menu {
                                    display: none;

                                    &.mobmenuSticky {
                                        box-shadow: 0 0 30px #366db3;
                                        display: flex;
                                        flex-direction: column;
                                        position: absolute;
                                        width: auto;
                                        right: 0;
                                        border-radius: 5px;
                                        top: 50px;
                                        background: #ffffff;
                                        width: 170px;
                                        z-index: 5555;

                                        li {
                                            margin-right: 0;

                                            a {
                                                color: #000;
                                                display: block;
                                                line-height: normal;
                                                padding: 10px 16px;
                                            }
                                        }
                                    }
                                }

                                .burger {
                                    opacity: 1;
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            .arrowDown {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                justify-items: center;
                -webkit-animation: arrowDown 2s infinite;
                animation: arrowDown 2s infinite;
                border: 2px solid #fff;
                border-radius: 50%;
                width: 34px;
                height: 34px;
                cursor: pointer;

                &::before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -40%) rotate(90deg);
                    content: '';
                    background-image: url(../../../static/images/arrow.svg);
                    background-repeat: no-repeat;
                    width: 20px;
                    height: 20px;
                    background-size: 20px 20px;
                }
            }

            @keyframes arrowDown {

                0%,
                20%,
                50%,
                80%,
                100% {
                    transform: translateY(0);
                }

                80% {
                    transform: translateY(-25px);
                }
            }

            @media (max-width: 1440px) {
                .bottomSide {
                    .leftSide {
                        margin-top: 0;

                        h2 {
                            font-size: 40px;
                            height: 50px;
                        }

                        .fillTitle {
                            height: 50px;
                        }
                    }

                    .rightSide {
                        img {
                            margin-top: -80px;
                        }
                    }
                }
            }

            @media (max-height: 800px) {
                .bottomSide {
                    margin-top: 5%;
                    margin-bottom: 65px;

                    .leftSide {
                        h2 {
                            font-size: 35px;
                            height: 43px;
                        }

                        .fillTitle {
                            line-height: 47px;
                        }

                        p {
                            margin: 80px 0 60px;
                        }
                    }
                }
            }

            @media (max-width: 1180px) {

                .sticky .inner .wrap .topSide,
                .topSide {
                    .logo {
                        width: 190px;
                        height: 42px;
                        background-size: 190px 42px;
                    }

                    .menu {

                        // li:last-child {
                        //     display: none;
                        // }
                        // li:nth-last-child(2) {
                        //     margin-right: 0;
                        // }
                        li {
                            margin-right: 18px;

                            a {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .bottomSide {
                    .leftSide {
                        margin: 0 auto 0;

                        h2 {
                            font-size: 21px !important;
                        }

                        .fillTitle {
                            line-height: 41px;
                        }

                        p {
                            font-size: 14.4px;
                            margin: 70px 0 50px;
                            width: 430px;
                        }

                        .buttonList {
                            li {
                                width: 155px;

                                a {
                                    font-size: 12px;
                                    padding: 11.2px 17.6px;
                                }

                                &:last-child {
                                    width: 205px;
                                }
                            }
                        }
                    }

                    .rightSide {
                        text-align: left;

                        img {
                            width: 441px;
                            height: 423px;
                            left: 0;
                            display: block;
                            margin: 0 auto;
                        }
                    }
                }
            }

            @media (max-width: 1024px) {

                .sticky .inner .wrap .topSide,
                .topSide {
                    .logo {
                        width: 170px;
                        height: 38px;
                        background-size: 170px 38px;
                    }

                    .menu {
                        li {
                            a {
                                font-size: 13px;
                            }

                            .register {
                                padding: 7px 10px;
                            }
                        }
                    }
                }

                .bottomSide {
                    .leftSide {
                        h1 {
                            font-size: 24px;
                            margin-bottom: 16px;
                        }

                        h2 {
                            font-size: 18px !important;
                        }

                        .slider {
                            margin: 22px 0 70px;
                            width: 385px;
                        }

                        p {
                            font-size: 13px;
                            width: 365px;
                        }

                        .buttonList {
                            li {
                                width: 150px;
                                margin-right: 10px;

                                a {
                                    padding: 8px 12px;
                                }

                                &:last-child {
                                    width: 200px;
                                }
                            }
                        }
                    }

                    .rightSide img {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            @media (max-width: 935px) {
                .topSide {
                    .logo {
                        width: 140px;
                        height: 34px;
                        background-size: 140px 34px;
                    }

                    .menu li {
                        margin-right: 14px;

                        &:last-child {
                            display: none;
                        }
                    }
                }
            }

            @media (max-width: 850px) {
                .bottomSide {
                    .rightSide {
                        img {
                            width: 90%;
                            margin: 0 0 0 auto;
                        }
                    }
                }
            }

            @media (max-width: 790px) {

                .sticky .inner .wrap .topSide,
                .topSide {
                    transition: .5s;

                    .menu {
                        display: none;
                        flex-direction: column;
                        position: fixed;
                        top: 0;
                        right: 0;
                        width: auto;
                        transition: .5s;
                        transform: translateX(100%);
                        animation-name: slidingMobMenuReverse;

                        @keyframes slidingMobMenu {
                            0% {
                                transform: translateX(100%);
                            }

                            100% {
                                transform: translateX(0);
                            }
                        }

                        &.mobmenu {
                            animation-name: slidingMobMenu;
                            animation-duration: .5s;
                            transform: translateX(0);
                            display: flex;
                            height: calc(100vh - 65px);
                            padding-top: 65px;
                            background-image: linear-gradient(to right, #2991CF, #4967AE);
                            width: 250px;
                            z-index: 7788;

                            li {
                                margin-right: 0;

                                a {
                                    color: #fff;
                                    display: block;
                                    font-size: 15px;
                                    font-weight: 500;
                                    line-height: normal;
                                    padding: 13px 40px;
                                    margin: 0 auto;
                                    text-align: left;
                                }

                                &:nth-last-child(3) {
                                    flex-grow: 1;
                                }

                                &:nth-last-child(2) {
                                    margin-bottom: 20px;

                                    a {
                                        text-align: center;
                                        width: 115px;
                                        padding: 10px 20px;
                                        border-radius: 5px;
                                        border: 1px solid #fff;
                                    }
                                }

                                &:last-child {
                                    display: block;
                                    margin-bottom: 45px;

                                    a {
                                        text-align: center;
                                        width: 115px;
                                        padding: 10px 20px;
                                        border: 1px solid #67b550;
                                    }
                                }
                            }
                        }

                        &~.burger.close {
                            right: 35px;
                        }
                    }

                    .burger {
                        display: block;
                        z-index: 7789;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                .bottomSide {
                    .leftSide {
                        h2 {
                            font-size: 16px !important;
                        }

                        .fillTitle {
                            line-height: 28px;
                        }

                        p {
                            margin: 60px 0 40px;
                            width: 100%;
                            font-size: 12px;
                        }

                        .slider {
                            width: 345px;
                        }

                        .buttonList {
                            flex-direction: column;

                            li {
                                margin-bottom: 15px;

                                a {
                                    display: flex;
                                    width: 210px;
                                    justify-content: center;
                                }
                            }
                        }
                    }

                    .rightSide {
                        img {
                            width: 95%;
                        }
                    }
                }
            }

            @media (max-width: 710px) {
                .bottomSide {
                    flex-direction: column;
                    margin-bottom: 50px;

                    .leftSide {
                        width: 100%;

                        h1 {
                            font-size: 23px;
                            margin: 0 auto;
                            width: 456px;
                        }

                        h2 {
                            margin: 0 auto !important;
                            text-align: center;
                        }

                        h2:first-child {
                            border-bottom-left-radius: 0;
                        }

                        h2:last-child {
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                        }

                        .mainImgMob {
                            display: block !important;
                            width: 70% !important;
                            margin: 0 auto 30px;
                            height: auto;
                        }

                        .slider {
                            margin: 20px auto 0;

                            .item {
                                p {
                                    font-size: 12px;
                                }
                            }
                        }

                        .fillTitle {
                            line-height: 43px;
                        }

                        p {
                            margin: 70px 0 50px;
                            font-size: 15px;
                        }

                        .buttonList {
                            display: none;
                        }
                    }

                    .rightSide {
                        width: 90%;

                        img {
                            display: none;
                        }

                        .buttonList {
                            display: flex;

                            li {
                                margin-top: 20px;
                                width: auto;

                                a {
                                    display: flex;
                                    justify-content: center;
                                    font-size: 14px;
                                    width: 270px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 530px) {
                .bottomSide {
                    margin-bottom: 0;

                    .rightSide {
                        img {
                            width: 100%;
                            left: 0;
                        }
                    }

                    .leftSide {
                        width: 100%;

                        h1 {
                            font-size: 19px;
                            width: 377px;
                        }

                        h2 {
                            box-sizing: border-box;
                            font-size: 28px;
                            height: 37px;
                        }

                        .fillTitle {
                            line-height: 39px;
                        }

                        p {
                            width: 100%;
                            font-size: 14px;
                        }
                    }
                }

                .arrowDown {
                    display: none;
                }
            }

            @media (max-width: 420px) {

                // div:last-child {
                //     div:first-child {
                //         padding-bottom: 20px !important;
                //     }
                // }
                .topSide {
                    height: 30px;

                    .logo {
                        width: 142px;
                        height: 34px;
                        background-size: 142px 34px;
                    }

                    .burger {
                        opacity: 1;
                        width: 35px;
                        height: 35px;
                        background-size: 35px 35px;

                        &.close {
                            position: fixed;
                        }
                    }
                }

                .bottomSide {
                    margin-top: 0 !important;

                    .leftSide {
                        margin-top: 45px;

                        h1 {
                            font-size: 16px;
                            width: 318px;
                        }

                        .mainImgMob {
                            width: 85% !important;
                        }

                        h2 {
                            display: block !important;
                            width: fit-content;
                            text-align: center;

                            .fillTitle {
                                line-height: 35px;
                                height: 32px;
                            }
                        }

                        h2:not(.m420) {
                            display: none !important;
                        }

                        .slider {
                            width: 90%;
                        }
                    }

                    .rightSide {
                        img {
                            height: auto;
                        }

                        .buttonList {
                            margin: 0;

                            li {
                                margin-top: 40px;

                                a {
                                    display: flex;
                                    margin: 0 auto;
                                    width: 227px;
                                    height: 40px;
                                    justify-content: center;
                                    align-items: center;
                                    font-size: 14px;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }

                .sticky {
                    .inner {
                        .wrap {
                            .topSide {
                                .logo {
                                    width: 138px;
                                    height: 30px;
                                    background-size: 138px 30px;
                                }

                                .burger {
                                    opacity: 1;
                                    width: 35px;
                                    height: 35px;
                                    background-size: 35px 35px;
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 375px) {
                .bottomSide {
                    .leftSide {
                        h1 {
                            width: 100%;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }

    .shadow {
        position: fixed;
        z-index: 5554;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: .6;
    }
}

@media (max-width: 710px) {
    .header {
        height: 100%;

        .inner .wrap {
            .bottomSide {
                margin-top: 10%;
            }
        }
    }
}