.section {
  position: relative;
  width: 100%;
  background: #fff;
}
.section .inner {
  height: inherit;
  box-sizing: border-box;
  padding: 2rem 3.5rem;
}
.section .inner .wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 111rem;
  height: 100%;
  margin: 0 auto;
}
.section .inner .wrap h2 {
  font-size: 34px;
  color: #101017;
  text-align: center;
  margin-bottom: 20px;
}
.section .inner .wrap h4 {
  font-size: 18px;
  font-weight: 400;
  color: #101017;
  text-align: center;
  margin-bottom: 60px;
}
.section .inner .wrap .imageBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section .inner .wrap .imageBlock .appList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 420px;
  margin-top: 40px;
}
@media (max-width: 800px) {
  .section .inner .wrap h2 {
    font-size: 22px;
  }
  .section .inner .wrap .imageBlock > img {
    width: 416px;
    height: 300px;
  }
  .section .inner .wrap .imageBlock .appList {
    width: 380px;
  }
}
@media (max-width: 530px) {
  .section .inner .wrap .imageBlock > img {
    width: 100%;
    height: auto;
  }
  .section .inner .wrap .imageBlock .appList {
    width: 100%;
    flex-direction: column;
  }
  .section .inner .wrap .imageBlock .appList img:first-child {
    margin-bottom: 10px;
  }
}
@media (max-width: 420px) {
  .section .inner .wrap h4 {
    font-size: 14px;
  }
}
