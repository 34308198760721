.section {
    position: relative;
    width: 100%;
    background: #fff;

    .inner {
        height: inherit;
        box-sizing: border-box;
        padding: 2rem 3.5rem;

        .wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            position: relative;
            max-width: 111rem;
            height: 100%;
            margin: 0 auto;

            h2 {
                font-size: 34px;
                color: #101017;
                text-align: center;
                margin-bottom: 20px;
            }

            h4 {
                font-size: 18px;
                font-weight: 400;
                color: #101017;
                text-align: center;
                margin-bottom: 60px;
            }

            .imageBlock {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .appList {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
                    width: 420px;
                    margin-top: 40px;
                }
            }

            @media (max-width: 800px) {
                h2 {
                    font-size: 22px;
                }

                .imageBlock {
                    &>img {
                        width: 416px;
                        height: 300px;
                    }

                    .appList {
                        width: 380px;
                    }
                }
            }

            @media (max-width: 530px) {
                .imageBlock {
                    &>img {
                        width: 100%;
                        height: auto;
                    }
                    .appList {
                        width: 100%;
                        flex-direction: column;
                        img:first-child {
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            @media (max-width: 420px) {
                h4 {
                    font-size: 14px;
                }
            }
        }
    }
}