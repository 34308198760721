.section {
    position: relative;
    width: 100%;
    background: #fff;

    .inner {
        height: inherit;
        box-sizing: border-box;
        padding: 6rem 3.5rem;

        .wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            position: relative;
            max-width: 111rem;
            height: 100%;
            margin: 0 auto;

            h2 {
                font-size: 34px;
                color: #101017;
                text-align: center;
                margin-bottom: 20px;
            }

            h4 {
                font-size: 18px;
                font-weight: 400;
                color: #101017;
                text-align: center;
                margin-bottom: 60px;
            }

            .exportList {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                list-style: none;
                width: 600px;
                margin-bottom: 60px;
            }

            @media (max-width: 1000px) {

                .exportList {
                    li {
                        img {
                            width: 80px;
                            height: 80px;
                        }
                    }
                }

                img {
                    width: 580px;
                    height: 420px;
                }
            }

            @media (max-width: 720px) {

                .exportList {
                    width: 360px;

                    li {
                        img {
                            width: 48px;
                            height: 48px;
                        }
                    }
                }

                img {
                    width: 400px;
                    height: 280px;
                }
            }
        }

        @media (max-width: 530px) {
            .wrap {
                &>img {
                    width: 100%;
                    height: auto;
                }
                .exportList {
                    width: 100%;
                    flex-flow: row wrap;
                    justify-content: space-around;
                    li {
                        margin: 0 5px;
                    }
                }
            }
        }

        @media (max-width: 420px) {
            .wrap {
                h4 {
                    margin-bottom: 30px;
                }

                .exportList {
                    width: 230px;
                    margin-bottom: 30px;
                    li {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .section {
        background: #F3F8FF;
        .inner {
            .wrap {
                h2 {
                    font-size: 22px;
                }
            }
        }
    }
}