.section {
  position: relative;
  width: 100%;
  background: #fff;
}
.section .inner {
  height: inherit;
  box-sizing: border-box;
  padding: 6rem 3.5rem;
}
.section .inner .wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 111rem;
  height: 100%;
  margin: 0 auto;
}
.section .inner .wrap h2 {
  font-size: 34px;
  color: #101017;
  text-align: center;
  margin-bottom: 60px;
}
@media (max-width: 420px) {
  .section .inner .wrap h2 {
    margin-bottom: 40px;
  }
}
.section .inner .wrap .listImage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.section .inner .wrap .listImage .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section .inner .wrap .listImage .item:last-child {
  margin-bottom: 0;
}
.section .inner .wrap .listImage .item p {
  width: 420px;
  font-size: 18px;
  text-align: center;
  margin-top: 48px;
}
@media (max-width: 800px) {
  .section .inner .wrap h2 {
    font-size: 22px;
  }
  .section .inner .wrap .listImage .item img {
    width: 420px;
    height: 360px;
  }
}
@media (max-width: 530px) {
  .section .inner .wrap .listImage .item img {
    width: 100%;
    height: auto;
  }
  .section .inner .wrap .listImage .item p {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .section .inner .wrap .listImage {
    flex-direction: column;
  }
  .section .inner .wrap .listImage .item:first-child {
    margin-bottom: 60px;
  }
}
