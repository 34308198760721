.modal {
    position: fixed;
    max-height: 95vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    background: #fff;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.15);
    border: 1px solid #3486C6;
    border-radius: 6px;
    z-index: 999999;
    overflow: auto;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .6);
        z-index: 99999;
    }

    .inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 3rem;
        box-sizing: border-box;

        h2 {
            font-size: 22px !important;
            font-weight: 400;
            margin-bottom: 20px;
            text-align: center;
        }

        p,
        label {
            user-select: none;
            font-size: 11px;
            margin-top: 7px;
            margin-bottom: 15px;

            input[type="checkbox"] {
                margin-right: 5px;
                width: auto;
            }
        }

        .button {
            padding: 10px 20px;
            font-size: 16px;
            border-radius: 5px;
            text-align: center;
            width: 220px;
            margin: 10px auto 0;
            cursor: not-allowed;
            opacity: 0.4;
            color: #000;
            user-select: none;
            background: #a8a8a8;

            &.active {
                opacity: 1;
                color: #fff;
                background: linear-gradient(94.51deg, #2991CF 3.96%, #4967AE 79.5%);

                &:hover {
                    cursor: pointer;
                    opacity: .8;
                }
            }

        }

        .close {
            position: absolute;
            top: 19px;
            right: 10px;
            width: 20px;
            height: 20px;

            &:hover {
                cursor: pointer;

                &:before,
                &:after {
                    background: linear-gradient(94.51deg, #2991CF 3.96%, #4967AE 79.5%);
                }
            }

            &:before,
            &:after {
                display: block;
                position: relative;
                content: '';
                width: 100%;
                height: 2px;
                background: #DDE0E3;
                transform: rotate(45deg);
            }

            &:after {
                top: -2px;
                transform: rotate(-45deg);
            }
        }
    }
}

@media (max-width: 530px) {
    .modal {
        width: 95%;
    }
}

@media (max-width: 420px) {
    .modal .inner h2 {
        font-size: 17px !important;
    }
}

.shadow {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.6;
}