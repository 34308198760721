.section {
    position: relative;
    width: 100%;
    background: #fff;

    .inner {
        height: inherit;
        box-sizing: border-box;
        padding: 6rem 3.5rem;

        .wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            max-width: 111rem;
            height: 100%;
            margin: 0 auto;

            h2 {
                font-size: 34px;
                color: #101017;
                text-align: center;
                margin-bottom: 60px;
            }

            .faqList {
                display: flex;
                flex-direction: column;
                
                list-style: none;

                li {
                    position: relative;
                    width: 100%;
                    padding: 20px 40px 20px 0;
                    box-sizing: border-box;
                    border-bottom: 2px solid #78A4D7;

                    &:hover {
                        cursor: pointer;
                        opacity: .8;
                    }

                    .circle {
                        position: absolute;
                        width: 28px;
                        height: 28px;
                        top: 17px;
                        right: 0;
                        border-radius: 50%;
                        background: linear-gradient(90deg, #1476BB 0%, #4967AE 74.9%);

                        &:after {
                            position: absolute;
                            content: "";
                            width: 18px;
                            height: 18px;
                            background-image: url('../../../static/images/plus.svg');
                            background-repeat: no-repeat;
                            background-size: 18px 18px;
                            right: 5px;
                            top: 5px;
                            z-index: 9;
                        }
                    }

                    .header {
                        font-size: 18px;
                    }

                    .description {
                        display: none;
                        font-size: 15px;
                        font-style: italic;
                        line-height: 1.6;
                        margin-top: 30px;
                    }
                }

                @media (max-width: 480px) {
                    li {
                        .header {
                            font-size: 15px;
                        }
                        .description {
                            line-height: 1.5;
                        }
                    }
                }

                .active {
                    .circle {
                        &:after {
                            background-image: url('../../../static/images/minus.svg');
                        }
                    }
                }
            }

            @media (max-width: 800px) {
                h2 {
                    font-size: 22px;
                }
            }

            @media (max-width: 420px) {
                h2 {
                    margin-bottom: 30px;
                }
                .faqList {
                    li {
                        .description {
                            font-size: 13px;
                            width: calc(100% + 40px);
                        }
                    }
                }
            }
        }
    }
}