#more {
  padding-bottom: 40px;
}
#more h2 {
  font-size: 19px!important;
  font-weight: 500;
  text-align: left;
  line-height: 1.5;
  margin-bottom: 16px;
}
#more h2,
#more h3 {
  font-family: Montserrat, Verdana, sans-serif;
}
#more h3,
#more h4,
#more h5,
#more h6 {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}
#more p {
  font-size: 15px !important;
  margin-top: 0;
  line-height: 1.5;
  margin-bottom: 12px;
}
#more ul {
  padding-inline-start: 40px;
}
#more ul li {
  margin-bottom: 15px;
  line-height: 1.5;
  font-size: 14px;
}
@media (max-width: 768px) {
  .modal .inner {
    padding: 2rem;
  }
  #more h2 {
    font-size: 14px !important;
  }
  #more p {
    font-size: 12px!important;
  }
  #more h3,
  #more h4,
  #more h5,
  #more h6 {
    font-size: 14px;
  }
  #more ul li {
    font-size: 11px;
  }
}
@media (max-width: 425px) {
  #more ul {
    padding-inline-start: 22px;
    font-size: 11px;
  }
}
