.section {
  position: relative;
  width: 100%;
  background: #fff;
}
.section .inner {
  height: inherit;
  box-sizing: border-box;
  padding: 6rem 3.5rem;
}
.section .inner .wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  max-width: 111rem;
  height: 100%;
  margin: 0 auto;
}
.section .inner .wrap .leftSide {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section .inner .wrap .leftSide h2 {
  font-size: 34px;
  color: #101017;
  text-align: left;
  margin-bottom: 30px;
}
.section .inner .wrap .leftSide p {
  text-align: left;
}
.section .inner .wrap .rightSide {
  width: 50%;
}
@media (max-width: 800px) {
  .section .inner .wrap .leftSide h2 {
    font-size: 22px;
  }
  .section .inner .wrap .rightSide {
    text-align: center;
  }
  .section .inner .wrap .rightSide img {
    width: 420px;
    height: 360px;
  }
}
@media (max-width: 530px) {
  .section .inner .wrap .rightSide img {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 1200px) {
  .section .inner .wrap {
    flex-direction: column;
  }
  .section .inner .wrap .leftSide,
  .section .inner .wrap .rightSide {
    width: 100%;
  }
  .section .inner .wrap .leftSide h2,
  .section .inner .wrap .rightSide h2 {
    text-align: center;
  }
  .section .inner .wrap .leftSide p,
  .section .inner .wrap .rightSide p {
    text-align: center;
  }
  .section .inner .wrap .leftSide {
    margin-bottom: 40px;
  }
  .section .inner .wrap .rightSide {
    text-align: center;
  }
}
@media (max-width: 800px) {
  .section {
    background: #F3F8FF;
  }
}
