.section {
    position: relative;
    width: 100%;
    background: #fff;

    .inner {
        height: inherit;
        box-sizing: border-box;
        padding: 6rem 3.5rem;

        .wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            max-width: 111rem;
            height: 100%;
            margin: 0 auto;

            h2 {
                font-size: 34px;
                color: #101017;
                text-align: center;
                margin-bottom: 60px;
            }

            @media (max-width: 420px) {
                h2 {
                    margin-bottom: 40px;
                }
            }

            .branchList {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                list-style: none;

                li {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    width: 350px;
                    height: 350px;
                    margin-bottom: 60px;

                    &:last-child {
                        margin-bottom: 0;
                        .button {
                            font-size: 16px;
                            text-align: left;
                            box-sizing: border-box;
                            padding-left: 20px;
                            line-height: 3;
                        }
                    }

                    .picture {
                        position: relative;
                        margin-bottom: 24px;

                        .playButton {
                            cursor: pointer;
                            width: 64px;
                            height: 64px;
                            border-radius:50%;
                            position: absolute;
                            top: 55%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            transition: 0.1s;
                            box-shadow: 0 0 10px #e04f5f;
                            
                            &:hover {
                                transform: translate(-50%, -50%) scale(1.2);
                                box-shadow: 0 0 15px #e04f5f;
                            }
                        }
                    }

                    .button {
                        width: 300px;
                        height: 50px;
                        color: #fff;
                        background: linear-gradient(144deg, #4967AE 3.96%, #2991CF 79.5%);
                        border-radius: 30px;
                        text-align: center;
                        line-height: 2.8;
                        font-size: 18px;
                        position: relative;

                        &:hover {
                            cursor: pointer;
                            opacity: .8;
                            &::before {
                                background: #ffffff;
                                opacity: 0.8;
                            }
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            transform: translateY(-50%);
                            background: #fff;
                            border-radius: 50%;
                            width: 35px;
                            height: 35px;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            right: 19px;
                            transform: translateY(-50%);
                            border-left: 13px solid #68b0dd;
                            border-top: 9px solid transparent;
                            border-bottom: 9px solid transparent;
                        }
                    }

                    @media (max-width: 530px) {
                        .picture {
                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }

                @media (max-width: 530px) {
                    li {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            @media (max-width: 800px) {

                h2 {
                    font-size: 22px;
                }

                .branchList {
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .section {
        background: #F3F8FF;
    }
}