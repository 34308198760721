.section {
    position: relative;
    width: 100%;
    height: 100%;

    .inner {
        .wrap {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 9px;
                background-image: linear-gradient(#ffffff, #d5f8ff);
                z-index: 5;
            }
            video {
                position: relative;
                top: 4px;
            }
        }
    }
}