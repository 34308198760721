.footer {
  position: relative;
  background: #2F4F66;
}
.footer .inner {
  height: inherit;
  padding: 3rem 3.5rem 0;
}
.footer .inner .wrap {
  display: flex;
  flex-direction: column;
  max-width: 111rem;
  margin: 0 auto;
  padding: 30px 0;
}
.footer .inner .wrap .socMedia {
  margin: 20px 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style-type: none;
}
.footer .inner .wrap .socMedia li {
  margin-right: 15px;
  margin-bottom: 0;
}
.footer .inner .wrap .menuList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer .inner .wrap .menuList .menu {
  display: flex;
  flex-direction: column;
  list-style: none;
}
.footer .inner .wrap .menuList .menu li {
  margin-bottom: 12px;
  font-size: 14px;
  color: #fff;
}
.footer .inner .wrap .menuList .menu li:first-child {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 500;
}
.footer .inner .wrap .menuList .menu li:first-child:hover {
  cursor: default;
}
.footer .inner .wrap .menuList .menu li:last-child {
  margin-bottom: 0;
}
.footer .inner .wrap .menuList .menu li a {
  color: #fff;
}
.footer .inner .wrap .menuList .menu li a:hover {
  text-decoration: underline;
}
.footer .inner .wrap .callMessage {
  display: none;
}
@media (max-width: 1160px) {
  .footer .inner .wrap .menuList {
    flex-direction: column;
  }
  .footer .inner .wrap .menuList .menu {
    width: 100% !important;
  }
  .footer .inner .wrap .menuList .menu:first-child li:first-child {
    height: unset !important;
    text-align: center;
  }
  .footer .inner .wrap .menuList .menu:first-child li:first-child:after {
    display: none;
  }
  .footer .inner .wrap .menuList .menu:first-child li:first-child a {
    position: relative !important;
    top: unset !important;
  }
  .footer .inner .wrap .menuList .menu:first-child li:nth-child(3),
  .footer .inner .wrap .menuList .menu:first-child li:nth-child(4),
  .footer .inner .wrap .menuList .menu:first-child li:nth-child(5),
  .footer .inner .wrap .menuList .menu:first-child li:nth-child(6) {
    display: none;
  }
  .footer .inner .wrap .menuList .menu:last-child li:not(:first-child) {
    text-align: left !important;
  }
  .footer .inner .wrap .menuList .menu li {
    position: relative;
  }
  .footer .inner .wrap .menuList .menu li:hover {
    cursor: pointer !important;
  }
  .footer .inner .wrap .menuList .menu li:first-child:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 12px;
    top: 0;
    right: 0;
    background: url('../../../static/images/arrow.svg') no-repeat;
  }
  .footer .inner .wrap .menuList .menu li:not(:first-child) {
    display: none;
  }
  .footer .inner .wrap .menuList .hideItem {
    margin-bottom: 34px;
  }
  .footer .inner .wrap .menuList .hideItem li:first-child:after {
    transform: rotate(90deg);
  }
  .footer .inner .wrap .menuList .hideItem li:not(:first-child) {
    display: block;
    padding-left: 20px;
  }
  .footer .inner .wrap .callMessage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0 0;
    list-style: none;
  }
  .footer .inner .wrap .callMessage li {
    margin-right: 20px;
  }
  .footer .inner .wrap .callMessage li:last-child {
    margin-right: 0;
  }
  .footer .inner .wrap .arrowUp {
    position: absolute;
    width: 36px;
    height: 36px;
    bottom: 30px;
    right: 20px;
    transform: translateX(-50%);
    display: flex;
    justify-items: center;
    border: 2px solid #fff;
    background: transparent;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    cursor: pointer;
  }
  .footer .inner .wrap .arrowUp::before {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -40%) rotate(270deg);
    content: '';
    background-image: url(../../../static/images/arrow.svg);
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
  }
}
@media (max-width: 338px) {
  .footer .inner .wrap .arrowUp {
    right: 0;
    bottom: 52px;
  }
}
.footer .inner .wrap h4 {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  margin-top: 40px;
}
