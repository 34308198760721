.section {
  position: relative;
  width: 100%;
  background: #fff;
}
.section .inner {
  height: inherit;
  box-sizing: border-box;
  padding: 6rem 3.5rem;
}
.section .inner .wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 111rem;
  height: 100%;
  margin: 0 auto;
}
.section .inner .wrap h2 {
  font-size: 34px;
  color: #101017;
  text-align: center;
  margin-bottom: 60px;
}
.section .inner .wrap .tarifList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-flow: row wrap;
  align-items: flex-start;
  list-style: none;
}
.section .inner .wrap .tarifList > li {
  width: 24%;
  margin: 0 15px 30px;
  border-radius: 10px;
  box-shadow: 3px 3px 10px 2px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.section .inner .wrap .tarifList > li .content ul li::after {
  content: '';
  position: absolute;
  left: 10px;
  top: 8px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.section .inner .wrap .tarifList > li.basic .header {
  background: linear-gradient(45deg, #6ddb87 3.96%, #4BAC61 79.5%);
}
.section .inner .wrap .tarifList > li.basic .content ul li:after {
  background: linear-gradient(45deg, #6ddb87 3.96%, #4BAC61 79.5%);
}
.section .inner .wrap .tarifList > li.basic .content .control .arrowDown {
  border-color: #4BAC61;
}
.section .inner .wrap .tarifList > li.basic .content .control .arrowDown:after {
  border-color: #4BAC61;
}
.section .inner .wrap .tarifList > li.standard .header {
  background: linear-gradient(45deg, #6d6ec0 3.96%, #4B4C7B 79.5%);
}
.section .inner .wrap .tarifList > li.standard .content ul li:after {
  background: linear-gradient(45deg, #6d6ec0 3.96%, #4B4C7B 79.5%);
}
.section .inner .wrap .tarifList > li.standard .content .control .arrowDown {
  border-color: #4B4C7B;
}
.section .inner .wrap .tarifList > li.standard .content .control .arrowDown:after {
  border-color: #4B4C7B;
}
.section .inner .wrap .tarifList > li.premium .header {
  background: linear-gradient(45deg, #5cb7e0 3.96%, #318AB2 79.5%);
}
.section .inner .wrap .tarifList > li.premium .content ul li:after {
  background: linear-gradient(45deg, #5cb7e0 3.96%, #318AB2 79.5%);
}
.section .inner .wrap .tarifList > li.premium .content .control .arrowDown {
  border-color: #318AB2;
}
.section .inner .wrap .tarifList > li.premium .content .control .arrowDown:after {
  border-color: #318AB2;
}
.section .inner .wrap .tarifList > li.elite .header {
  background: linear-gradient(45deg, #c969cc 3.96%, #924794 79.5%);
}
.section .inner .wrap .tarifList > li.elite .content ul li:after {
  background: linear-gradient(45deg, #c969cc 3.96%, #924794 79.5%);
}
.section .inner .wrap .tarifList > li.elite .content .control .arrowDown {
  border-color: #924794;
}
.section .inner .wrap .tarifList > li.elite .content .control .arrowDown:after {
  border-color: #924794;
}
.section .inner .wrap .tarifList > li.enterprise .header {
  background: linear-gradient(45deg, #95c9ec 3.96%, #80B5D8 79.5%);
}
.section .inner .wrap .tarifList > li.enterprise .content ul li:first-child span {
  position: absolute;
  top: -14px;
  width: 180px;
  font-size: 15px;
}
.section .inner .wrap .tarifList > li.enterprise .content ul li:after {
  background: linear-gradient(45deg, #95c9ec 3.96%, #80B5D8 79.5%);
}
.section .inner .wrap .tarifList > li.enterprise .content .control .arrowDown {
  border-color: #80B5D8;
}
.section .inner .wrap .tarifList > li.enterprise .content .control .arrowDown:after {
  border-color: #80B5D8;
}
.section .inner .wrap .tarifList > li .header {
  width: 100%;
  height: 60px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  line-height: 60px;
}
.section .inner .wrap .tarifList > li .content {
  background: #FAFAFA;
  padding: 60px 20px 20px;
}
.section .inner .wrap .tarifList > li .content ul {
  list-style: none;
}
.section .inner .wrap .tarifList > li .content ul li {
  position: relative;
  width: 100%;
  height: 80px;
  font-size: 22px;
  font-weight: 500;
  color: #101017;
  padding-left: 40px;
}
.section .inner .wrap .tarifList > li .content ul li span {
  font-size: 16px;
  font-weight: 400;
}
.section .inner .wrap .tarifList > li .content .control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section .inner .wrap .tarifList > li .content .control a {
  font-size: 18px;
  text-decoration: underline;
  margin-bottom: 14px;
}
.section .inner .wrap .tarifList > li .content .control a:hover {
  cursor: pointer;
  opacity: 0.8;
}
.section .inner .wrap .tarifList > li .content .control .arrowDown {
  position: relative;
  width: 16px;
  height: 16px;
  border-top: 3px solid #53a3ff;
  border-right: 3px solid #53a3ff;
  border-radius: 1px;
  transform: rotate(134deg);
  margin-bottom: 20px;
}
.section .inner .wrap .tarifList > li .content .control .arrowDown:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 0px;
  width: 10px;
  height: 10px;
  border-top: 3px solid #53a3ff;
  border-right: 3px solid #53a3ff;
  border-radius: 1px;
  transform: rotate(0deg);
}
.section .inner .wrap .tarifList > li .content .control .button {
  background: linear-gradient(94.51deg, #2991CF 3.96%, #4967AE 79.5%);
  border-radius: 30px;
  font-size: 14px;
  color: #fff;
  padding: 12px 35px;
  text-decoration: none;
}
.section .inner .wrap .tarifList > li .content .control .button:hover {
  cursor: pointer;
  opacity: 0.8;
}
.section .inner .wrap p {
  font-size: 14px;
  text-align: center;
}
@media (max-width: 1100px) {
  .section .inner .wrap .tarifList {
    flex-direction: column;
    align-items: center;
  }
  .section .inner .wrap .tarifList > li {
    width: 278px;
    margin-bottom: 40px;
  }
}
@media (max-width: 800px) {
  .section .inner .wrap h2 {
    font-size: 22px;
  }
}
@media (max-width: 420px) {
  .section .inner .wrap h2 {
    margin-bottom: 40px;
  }
}
@media (max-width: 375px) {
  .section .inner .wrap .tarifList > li {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }
  .section .inner .wrap .tarifList > li .content {
    padding: 40px 15px 20px;
  }
  .section .inner .wrap .tarifList > li .content ul li {
    height: 60px;
  }
}
@media (max-width: 320px) {
  .section .inner .wrap .tarifList > li {
    width: 100%;
  }
}
