#more {
  padding-bottom: 40px;
  h2 {
    font-size: 19px!important;
    font-weight: 500;
    text-align: left;
    line-height: 1.5;
    margin-bottom: 16px;
  }
  h2, h3 {
    font-family: Montserrat,Verdana,sans-serif;
  }
  h3, h4, h5, h6 {
    margin-top: 30px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  p {
    font-size: 15px !important;
    margin-top: 0;
    line-height: 1.5;
    margin-bottom: 12px;
  }
  ul {
    padding-inline-start: 40px;
    li {
      margin-bottom: 15px;
      line-height: 1.5;
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .modal .inner {
    padding: 2rem;
  }
  #more {
    h2 {
      font-size: 14px !important;
    }
    p {
      font-size: 12px!important;
    }
    h3, h4, h5, h6 {
      font-size: 14px;
    }
    ul li {
      font-size: 11px;
    }
  }
}

@media (max-width: 425px) {
  #more {
    ul {
      padding-inline-start: 22px;
      font-size: 11px;
    }
  }
}