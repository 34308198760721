.header {
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(100.28deg, #1476BB 12.13%, #4967AE 77.75%);
}
.header .inner {
  height: inherit;
  box-sizing: border-box;
  padding: 3rem 3.5rem;
}
.header .inner .wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 111rem;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}
.header .inner .wrap .topSide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  z-index: 9;
}
.header .inner .wrap .topSide .logo {
  width: 211px;
  height: 46px;
  background: url('../../../static/images/logo/11q2.png') no-repeat;
}
.header .inner .wrap .topSide .menu {
  display: flex;
  flex-direction: row;
  list-style: none;
}
.header .inner .wrap .topSide .menu li {
  margin-right: 20px;
  line-height: 2.5;
}
.header .inner .wrap .topSide .menu li:last-child {
  margin-right: 0;
}
.header .inner .wrap .topSide .menu li a {
  font-size: 16px;
  color: #fff;
}
.header .inner .wrap .topSide .menu li a:hover {
  text-decoration: underline;
}
.header .inner .wrap .topSide .menu li .register {
  background: #67B550;
  border-radius: 5px;
  padding: 10px 16px;
}
.header .inner .wrap .topSide .burger {
  display: none;
  width: 40px;
  height: 40px;
  background-image: url('../../../static/images/burger2.svg');
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.header .inner .wrap .topSide .burger.close {
  background-image: url('../../../static/images/close.svg');
}
.header .inner .wrap .topSide .burger:hover {
  cursor: pointer;
  opacity: 0.8;
}
.header .inner .wrap .bottomSide {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 80px;
}
.header .inner .wrap .bottomSide .leftSide {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: -35px;
}
.header .inner .wrap .bottomSide .leftSide h1,
.header .inner .wrap .bottomSide .leftSide h2 {
  color: #fff;
  font-family: 'Montserrat', Verdana, sans-serif;
  font-weight: 700;
}
.header .inner .wrap .bottomSide .leftSide h1 {
  font-size: 28px;
  width: 555px;
  margin-bottom: 20px;
  line-height: 1.2;
}
.header .inner .wrap .bottomSide .leftSide h2 {
  display: inline;
  text-align: left;
  height: auto !important;
  font-size: 24px !important;
  background: #FFFFFF;
  color: #1D7ABE;
  padding: 5px 12px;
  border-radius: 8px;
  width: max-content;
  z-index: 1;
}
.header .inner .wrap .bottomSide .leftSide h2.fillTitle {
  background: #fff;
  color: #1D7ABE;
  padding: 0 6px 3px;
  border-radius: 8px;
  height: auto;
}
.header .inner .wrap .bottomSide .leftSide .slider {
  display: block;
  background-color: #3E79C1;
  padding: 5px 13px;
  border-radius: 8px;
  margin: 30px 0 70px;
  width: 458px;
}
.header .inner .wrap .bottomSide .leftSide .slider .item {
  color: #fff;
  font-size: 21px;
}
.header .inner .wrap .bottomSide .leftSide .slider .item p {
  margin: 0;
}
.header .inner .wrap .bottomSide .leftSide p {
  text-align: left;
  color: #fff;
  margin: 100px 0 80px;
  line-height: 1.6;
  letter-spacing: 0.04px;
  font-size: 16px;
}
.header .inner .wrap .bottomSide .leftSide .buttonList {
  display: flex;
  flex-direction: row;
  list-style: none;
}
.header .inner .wrap .bottomSide .leftSide .buttonList li {
  width: 214px;
  margin-right: 20px;
}
.header .inner .wrap .bottomSide .leftSide .buttonList li:last-child {
  width: 290px;
  margin-right: 0;
}
.header .inner .wrap .bottomSide .leftSide .buttonList li:last-child a {
  border-color: #FFFFFF;
  background: none;
}
.header .inner .wrap .bottomSide .leftSide .buttonList li:last-child a:hover {
  background: #62BB50;
}
.header .inner .wrap .bottomSide .leftSide .buttonList li a {
  font-size: 14px;
  color: #fff;
  background: #62BB50;
  border-radius: 100px;
  box-sizing: border-box;
  padding: 14px 22px;
  border: 2px solid #62BB50;
}
.header .inner .wrap .bottomSide .leftSide .buttonList li a:hover {
  border: 2px solid #FFFFFF;
}
.header .inner .wrap .bottomSide .rightSide {
  width: 50%;
  position: relative;
  text-align: right;
}
.header .inner .wrap .bottomSide .rightSide img {
  position: relative;
  margin-top: -134px;
}
.header .inner .wrap .bottomSide .rightSide .buttonList {
  display: none;
  flex-direction: column;
  align-items: center;
  list-style: none;
  margin: 20px 0;
  text-align: center;
}
.header .inner .wrap .bottomSide .rightSide .buttonList li {
  width: 260px;
  margin-top: 48px;
}
.header .inner .wrap .bottomSide .rightSide .buttonList li:last-child a {
  border-color: #FFFFFF;
  background: none;
}
.header .inner .wrap .bottomSide .rightSide .buttonList li:last-child a:hover {
  background: #62BB50;
}
.header .inner .wrap .bottomSide .rightSide .buttonList li a {
  font-size: 14px;
  color: #fff;
  background: #62BB50;
  border-radius: 100px;
  box-sizing: border-box;
  padding: 12px 32px;
  border: 2px solid #62BB50;
}
.header .inner .wrap .bottomSide .rightSide .buttonList li a:hover {
  border: 2px solid #FFFFFF;
}
.header .inner .wrap .bottomSide .rightSide .owl {
  position: absolute;
  top: 20.4%;
  left: 30.4%;
}
.header .inner .wrap .bottomSide .rightSide .robot {
  position: absolute;
  bottom: 12%;
  right: 27%;
}
.header .inner .wrap .sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  transform: translateZ(0px);
  background: linear-gradient(100.28deg, #1476BB 12.13%, #4967AE 77.75%);
  z-index: 999999;
}
.header .inner .wrap .sticky .inner {
  height: inherit;
  box-sizing: border-box;
  padding: 1rem 3.5rem;
}
.header .inner .wrap .sticky .inner .wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 111rem;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}
.header .inner .wrap .sticky .inner .wrap .topSide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
}
.header .inner .wrap .sticky .inner .wrap .topSide .logo {
  width: 201px;
  height: 36px;
  background-image: url('../../../static/images/logo/11q2.png');
  background-repeat: no-repeat;
  background-size: 201px 36px;
}
.header .inner .wrap .sticky .inner .wrap .topSide .menu {
  display: flex;
  flex-direction: row;
  list-style: none;
}
.header .inner .wrap .sticky .inner .wrap .topSide .menu li {
  margin-right: 20px;
  line-height: 2.5;
}
.header .inner .wrap .sticky .inner .wrap .topSide .menu li:last-child {
  margin-right: 0;
}
.header .inner .wrap .sticky .inner .wrap .topSide .menu li a {
  font-size: 16px;
  color: #fff;
}
.header .inner .wrap .sticky .inner .wrap .topSide .menu li a:hover {
  text-decoration: underline;
}
.header .inner .wrap .sticky .inner .wrap .topSide .menu li .register {
  background: #67B550;
  border-radius: 5px;
  padding: 8px 12px;
}
.header .inner .wrap .sticky .inner .wrap .topSide .burger {
  display: none;
  width: 40px;
  height: 40px;
  background-image: url('../../../static/images/burger2.svg');
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.header .inner .wrap .sticky .inner .wrap .topSide .burger.close {
  background-image: url('../../../static/images/close.svg');
}
.header .inner .wrap .sticky .inner .wrap .topSide .burger:hover {
  cursor: pointer;
  opacity: 0.8;
}
@media (max-width: 988px) {
  .header .inner .wrap .sticky .inner .wrap .topSide .logo {
    width: 165px;
    height: 36px;
    background-size: 165px 36px;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu li {
    margin-right: 14px;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu li a {
    font-size: 15px;
  }
}
@media (max-width: 850px) {
  .header .inner .wrap .sticky .inner .wrap .topSide .menu {
    display: none;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu.mobmenuSticky {
    box-shadow: 0 0 30px #366db3;
    display: flex;
    flex-direction: column;
    position: absolute;
    width: auto;
    right: 0;
    border-radius: 5px;
    top: 50px;
    background: #ffffff;
    width: 170px;
    z-index: 5555;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu.mobmenuSticky li {
    margin-right: 0;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu.mobmenuSticky li a {
    color: #000;
    display: block;
    line-height: normal;
    padding: 10px 16px;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .burger {
    opacity: 1;
    display: block;
  }
}
.header .inner .wrap .arrowDown {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-items: center;
  -webkit-animation: arrowDown 2s infinite;
  animation: arrowDown 2s infinite;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  cursor: pointer;
}
.header .inner .wrap .arrowDown::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%) rotate(90deg);
  content: '';
  background-image: url(../../../static/images/arrow.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
}
@keyframes arrowDown {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(-25px);
  }
}
@media (max-width: 1440px) {
  .header .inner .wrap .bottomSide .leftSide {
    margin-top: 0;
  }
  .header .inner .wrap .bottomSide .leftSide h2 {
    font-size: 40px;
    height: 50px;
  }
  .header .inner .wrap .bottomSide .leftSide .fillTitle {
    height: 50px;
  }
  .header .inner .wrap .bottomSide .rightSide img {
    margin-top: -80px;
  }
}
@media (max-height: 800px) {
  .header .inner .wrap .bottomSide {
    margin-top: 5%;
    margin-bottom: 65px;
  }
  .header .inner .wrap .bottomSide .leftSide h2 {
    font-size: 35px;
    height: 43px;
  }
  .header .inner .wrap .bottomSide .leftSide .fillTitle {
    line-height: 47px;
  }
  .header .inner .wrap .bottomSide .leftSide p {
    margin: 80px 0 60px;
  }
}
@media (max-width: 1180px) {
  .header .inner .wrap .sticky .inner .wrap .topSide .logo,
  .header .inner .wrap .topSide .logo {
    width: 190px;
    height: 42px;
    background-size: 190px 42px;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu li,
  .header .inner .wrap .topSide .menu li {
    margin-right: 18px;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu li a,
  .header .inner .wrap .topSide .menu li a {
    font-size: 14px;
  }
  .header .inner .wrap .bottomSide .leftSide {
    margin: 0 auto 0;
  }
  .header .inner .wrap .bottomSide .leftSide h2 {
    font-size: 21px !important;
  }
  .header .inner .wrap .bottomSide .leftSide .fillTitle {
    line-height: 41px;
  }
  .header .inner .wrap .bottomSide .leftSide p {
    font-size: 14.4px;
    margin: 70px 0 50px;
    width: 430px;
  }
  .header .inner .wrap .bottomSide .leftSide .buttonList li {
    width: 155px;
  }
  .header .inner .wrap .bottomSide .leftSide .buttonList li a {
    font-size: 12px;
    padding: 11.2px 17.6px;
  }
  .header .inner .wrap .bottomSide .leftSide .buttonList li:last-child {
    width: 205px;
  }
  .header .inner .wrap .bottomSide .rightSide {
    text-align: left;
  }
  .header .inner .wrap .bottomSide .rightSide img {
    width: 441px;
    height: 423px;
    left: 0;
    display: block;
    margin: 0 auto;
  }
}
@media (max-width: 1024px) {
  .header .inner .wrap .sticky .inner .wrap .topSide .logo,
  .header .inner .wrap .topSide .logo {
    width: 170px;
    height: 38px;
    background-size: 170px 38px;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu li a,
  .header .inner .wrap .topSide .menu li a {
    font-size: 13px;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu li .register,
  .header .inner .wrap .topSide .menu li .register {
    padding: 7px 10px;
  }
  .header .inner .wrap .bottomSide .leftSide h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  .header .inner .wrap .bottomSide .leftSide h2 {
    font-size: 18px !important;
  }
  .header .inner .wrap .bottomSide .leftSide .slider {
    margin: 22px 0 70px;
    width: 385px;
  }
  .header .inner .wrap .bottomSide .leftSide p {
    font-size: 13px;
    width: 365px;
  }
  .header .inner .wrap .bottomSide .leftSide .buttonList li {
    width: 150px;
    margin-right: 10px;
  }
  .header .inner .wrap .bottomSide .leftSide .buttonList li a {
    padding: 8px 12px;
  }
  .header .inner .wrap .bottomSide .leftSide .buttonList li:last-child {
    width: 200px;
  }
  .header .inner .wrap .bottomSide .rightSide img {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 935px) {
  .header .inner .wrap .topSide .logo {
    width: 140px;
    height: 34px;
    background-size: 140px 34px;
  }
  .header .inner .wrap .topSide .menu li {
    margin-right: 14px;
  }
  .header .inner .wrap .topSide .menu li:last-child {
    display: none;
  }
}
@media (max-width: 850px) {
  .header .inner .wrap .bottomSide .rightSide img {
    width: 90%;
    margin: 0 0 0 auto;
  }
}
@media (max-width: 790px) {
  .header .inner .wrap .sticky .inner .wrap .topSide,
  .header .inner .wrap .topSide {
    transition: 0.5s;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu,
  .header .inner .wrap .topSide .menu {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    width: auto;
    transition: 0.5s;
    transform: translateX(100%);
    animation-name: slidingMobMenuReverse;
  }
  @keyframes slidingMobMenu {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu.mobmenu,
  .header .inner .wrap .topSide .menu.mobmenu {
    animation-name: slidingMobMenu;
    animation-duration: 0.5s;
    transform: translateX(0);
    display: flex;
    height: calc(100vh - 65px);
    padding-top: 65px;
    background-image: linear-gradient(to right, #2991CF, #4967AE);
    width: 250px;
    z-index: 7788;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu.mobmenu li,
  .header .inner .wrap .topSide .menu.mobmenu li {
    margin-right: 0;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu.mobmenu li a,
  .header .inner .wrap .topSide .menu.mobmenu li a {
    color: #fff;
    display: block;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
    padding: 13px 40px;
    margin: 0 auto;
    text-align: left;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu.mobmenu li:nth-last-child(3),
  .header .inner .wrap .topSide .menu.mobmenu li:nth-last-child(3) {
    flex-grow: 1;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu.mobmenu li:nth-last-child(2),
  .header .inner .wrap .topSide .menu.mobmenu li:nth-last-child(2) {
    margin-bottom: 20px;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu.mobmenu li:nth-last-child(2) a,
  .header .inner .wrap .topSide .menu.mobmenu li:nth-last-child(2) a {
    text-align: center;
    width: 115px;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #fff;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu.mobmenu li:last-child,
  .header .inner .wrap .topSide .menu.mobmenu li:last-child {
    display: block;
    margin-bottom: 45px;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu.mobmenu li:last-child a,
  .header .inner .wrap .topSide .menu.mobmenu li:last-child a {
    text-align: center;
    width: 115px;
    padding: 10px 20px;
    border: 1px solid #67b550;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .menu ~ .burger.close,
  .header .inner .wrap .topSide .menu ~ .burger.close {
    right: 35px;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .burger,
  .header .inner .wrap .topSide .burger {
    display: block;
    z-index: 7789;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .burger:hover,
  .header .inner .wrap .topSide .burger:hover {
    opacity: 1;
  }
  .header .inner .wrap .bottomSide .leftSide h2 {
    font-size: 16px !important;
  }
  .header .inner .wrap .bottomSide .leftSide .fillTitle {
    line-height: 28px;
  }
  .header .inner .wrap .bottomSide .leftSide p {
    margin: 60px 0 40px;
    width: 100%;
    font-size: 12px;
  }
  .header .inner .wrap .bottomSide .leftSide .slider {
    width: 345px;
  }
  .header .inner .wrap .bottomSide .leftSide .buttonList {
    flex-direction: column;
  }
  .header .inner .wrap .bottomSide .leftSide .buttonList li {
    margin-bottom: 15px;
  }
  .header .inner .wrap .bottomSide .leftSide .buttonList li a {
    display: flex;
    width: 210px;
    justify-content: center;
  }
  .header .inner .wrap .bottomSide .rightSide img {
    width: 95%;
  }
}
@media (max-width: 710px) {
  .header .inner .wrap .bottomSide {
    flex-direction: column;
    margin-bottom: 50px;
  }
  .header .inner .wrap .bottomSide .leftSide {
    width: 100%;
  }
  .header .inner .wrap .bottomSide .leftSide h1 {
    font-size: 23px;
    margin: 0 auto;
    width: 456px;
  }
  .header .inner .wrap .bottomSide .leftSide h2 {
    margin: 0 auto !important;
    text-align: center;
  }
  .header .inner .wrap .bottomSide .leftSide h2:first-child {
    border-bottom-left-radius: 0;
  }
  .header .inner .wrap .bottomSide .leftSide h2:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .header .inner .wrap .bottomSide .leftSide .mainImgMob {
    display: block !important;
    width: 70% !important;
    margin: 0 auto 30px;
    height: auto;
  }
  .header .inner .wrap .bottomSide .leftSide .slider {
    margin: 20px auto 0;
  }
  .header .inner .wrap .bottomSide .leftSide .slider .item p {
    font-size: 12px;
  }
  .header .inner .wrap .bottomSide .leftSide .fillTitle {
    line-height: 43px;
  }
  .header .inner .wrap .bottomSide .leftSide p {
    margin: 70px 0 50px;
    font-size: 15px;
  }
  .header .inner .wrap .bottomSide .leftSide .buttonList {
    display: none;
  }
  .header .inner .wrap .bottomSide .rightSide {
    width: 90%;
  }
  .header .inner .wrap .bottomSide .rightSide img {
    display: none;
  }
  .header .inner .wrap .bottomSide .rightSide .buttonList {
    display: flex;
  }
  .header .inner .wrap .bottomSide .rightSide .buttonList li {
    margin-top: 20px;
    width: auto;
  }
  .header .inner .wrap .bottomSide .rightSide .buttonList li a {
    display: flex;
    justify-content: center;
    font-size: 14px;
    width: 270px;
    text-align: center;
  }
}
@media (max-width: 530px) {
  .header .inner .wrap .bottomSide {
    margin-bottom: 0;
  }
  .header .inner .wrap .bottomSide .rightSide img {
    width: 100%;
    left: 0;
  }
  .header .inner .wrap .bottomSide .leftSide {
    width: 100%;
  }
  .header .inner .wrap .bottomSide .leftSide h1 {
    font-size: 19px;
    width: 377px;
  }
  .header .inner .wrap .bottomSide .leftSide h2 {
    box-sizing: border-box;
    font-size: 28px;
    height: 37px;
  }
  .header .inner .wrap .bottomSide .leftSide .fillTitle {
    line-height: 39px;
  }
  .header .inner .wrap .bottomSide .leftSide p {
    width: 100%;
    font-size: 14px;
  }
  .header .inner .wrap .arrowDown {
    display: none;
  }
}
@media (max-width: 420px) {
  .header .inner .wrap .topSide {
    height: 30px;
  }
  .header .inner .wrap .topSide .logo {
    width: 142px;
    height: 34px;
    background-size: 142px 34px;
  }
  .header .inner .wrap .topSide .burger {
    opacity: 1;
    width: 35px;
    height: 35px;
    background-size: 35px 35px;
  }
  .header .inner .wrap .topSide .burger.close {
    position: fixed;
  }
  .header .inner .wrap .bottomSide {
    margin-top: 0 !important;
  }
  .header .inner .wrap .bottomSide .leftSide {
    margin-top: 45px;
  }
  .header .inner .wrap .bottomSide .leftSide h1 {
    font-size: 16px;
    width: 318px;
  }
  .header .inner .wrap .bottomSide .leftSide .mainImgMob {
    width: 85% !important;
  }
  .header .inner .wrap .bottomSide .leftSide h2 {
    display: block !important;
    width: fit-content;
    text-align: center;
  }
  .header .inner .wrap .bottomSide .leftSide h2 .fillTitle {
    line-height: 35px;
    height: 32px;
  }
  .header .inner .wrap .bottomSide .leftSide h2:not(.m420) {
    display: none !important;
  }
  .header .inner .wrap .bottomSide .leftSide .slider {
    width: 90%;
  }
  .header .inner .wrap .bottomSide .rightSide img {
    height: auto;
  }
  .header .inner .wrap .bottomSide .rightSide .buttonList {
    margin: 0;
  }
  .header .inner .wrap .bottomSide .rightSide .buttonList li {
    margin-top: 40px;
  }
  .header .inner .wrap .bottomSide .rightSide .buttonList li a {
    display: flex;
    margin: 0 auto;
    width: 227px;
    height: 40px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 0;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .logo {
    width: 138px;
    height: 30px;
    background-size: 138px 30px;
  }
  .header .inner .wrap .sticky .inner .wrap .topSide .burger {
    opacity: 1;
    width: 35px;
    height: 35px;
    background-size: 35px 35px;
  }
}
@media (max-width: 375px) {
  .header .inner .wrap .bottomSide .leftSide h1 {
    width: 100%;
    font-weight: 500;
  }
}
.header .shadow {
  position: fixed;
  z-index: 5554;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
}
@media (max-width: 710px) {
  .header {
    height: 100%;
  }
  .header .inner .wrap .bottomSide {
    margin-top: 10%;
  }
}
