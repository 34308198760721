.inputWrap {
    width: inherit;
    margin-bottom: 10px;
  
  input {
    width: 100%;
    background: #F2F2F2;
    border-radius: 5px;
    border: 1px solid #3486C6;
    outline: none;
    padding: 10px 20px;
    box-sizing: border-box;
    &.error {
      border: 1px solid #ce0909;
      &::placeholder {
        color: #ce0909;
      }
    }
  }
}
  
.successText {
  font-size: 18px !important;
  text-align: center;
  color: #67B451;
}
  
.button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
  width: 220px;
  margin: 10px auto 0;
  cursor: not-allowed;
  color: #fff;
  cursor: pointer;
  user-select: none;
  background: linear-gradient(94.51deg, #2991CF 3.96%, #4967AE 79.5%);
  display: block;
  border: none;
  &:hover {
    opacity: .8;
  }
}