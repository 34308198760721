.section {
  position: relative;
  width: 100%;
  background: #fff;
}
.section .inner {
  height: inherit;
  box-sizing: border-box;
  padding: 6rem 3.5rem;
}
.section .inner .wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 111rem;
  height: 100%;
  margin: 0 auto;
}
.section .inner .wrap h2 {
  font-size: 28px;
  color: #101017;
  text-align: center;
  margin-bottom: 60px;
}
.section .inner .wrap .listImage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.section .inner .wrap .listImage .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section .inner .wrap .listImage .item:last-child {
  margin-bottom: 0;
}
.section .inner .wrap .listImage .item .form {
  width: 440px;
  background: #FAFAFA;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}
.section .inner .wrap .listImage .item .form .header {
  background: linear-gradient(94.51deg, #2991CF 3.96%, #4967AE 79.5%);
  color: #fff;
  padding: 24px;
  font-size: 18px;
  text-align: center;
}
.section .inner .wrap .listImage .item .form .content {
  padding: 24px 38px;
  box-sizing: border-box;
}
.section .inner .wrap .listImage .item .form .content form {
  display: flex;
  flex-direction: column;
}
.section .inner .wrap .listImage .item .form .content form .row {
  margin-bottom: 10px;
}
.section .inner .wrap .listImage .item .form .content form .row:last-child {
  text-align: center;
  margin: 20px auto 0;
}
.section .inner .wrap .listImage .item .form .content form .row:nth-child(5) {
  margin-bottom: 20px;
}
.section .inner .wrap .listImage .item .form .content form .row input {
  width: 100%;
  height: 30px;
  border: none;
  background: #FAFAFA;
  border-bottom: 2px solid #D7D7D7;
  outline: none;
}
.section .inner .wrap .listImage .item .form .content form .row input[type=checkbox] {
  width: auto;
  height: auto;
}
.section .inner .wrap .listImage .item .form .content form .row input.error {
  border-bottom: 2px solid #ce0909;
}
.section .inner .wrap .listImage .item .form .content form .row input.error::placeholder {
  color: #ce0909;
}
.section .inner .wrap .listImage .item .form .content form .row p {
  font-size: 12px;
}
.section .inner .wrap .listImage .item .form .content form .row label {
  display: block;
  margin-bottom: 10px;
}
.section .inner .wrap .listImage .item .form .content form .row label input[type=checkbox] {
  margin-right: 5px;
}
.section .inner .wrap .listImage .item .form .content form .row .successText {
  font-size: 18px;
  color: #67B451;
}
.section .inner .wrap .listImage .item .form .content form .row .button {
  display: block;
  width: 220px;
  background: #67B451;
  border-radius: 100px;
  color: #fff;
  padding: 14px 0;
  border: none;
  user-select: none;
  font-size: 16px;
  cursor: pointer;
}
.section .inner .wrap .listImage .item .form .content form .row .button:hover {
  opacity: 0.8;
}
@media (max-width: 800px) {
  .section .inner .wrap .listImage .item img {
    width: 420px;
    height: 360px;
  }
  .section .inner .wrap .listImage .item .form {
    width: 380px;
  }
}
@media (max-width: 530px) {
  .section .inner .wrap .listImage .item img {
    width: 100%;
    height: auto;
  }
  .section .inner .wrap .listImage .item .form {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .section .inner .wrap .listImage {
    flex-direction: column;
  }
  .section .inner .wrap .listImage .item:first-child {
    margin-bottom: 60px;
  }
}
@media (max-width: 530px) {
  .section .inner {
    padding-top: 0;
  }
}
@media (max-width: 375px) {
  .section .inner .wrap .listImage .item .form .content {
    padding: 20px;
  }
  .section .inner .wrap .listImage .item .form .content form .row .button {
    width: 200px;
    font-size: 15px;
  }
}
