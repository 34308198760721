.section {
  position: relative;
  width: 100%;
  background: #fff;
}
.section .inner {
  height: inherit;
  box-sizing: border-box;
  padding: 6rem 3.5rem;
}
.section .inner .wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 111rem;
  height: 100%;
  margin: 0 auto;
}
.section .inner .wrap h2 {
  font-size: 34px;
  color: #101017;
  text-align: center;
  margin-bottom: 60px;
}
.section .inner .wrap .faqList {
  display: flex;
  flex-direction: column;
  list-style: none;
}
.section .inner .wrap .faqList li {
  position: relative;
  width: 100%;
  padding: 20px 40px 20px 0;
  box-sizing: border-box;
  border-bottom: 2px solid #78A4D7;
}
.section .inner .wrap .faqList li:hover {
  cursor: pointer;
  opacity: 0.8;
}
.section .inner .wrap .faqList li .circle {
  position: absolute;
  width: 28px;
  height: 28px;
  top: 17px;
  right: 0;
  border-radius: 50%;
  background: linear-gradient(90deg, #1476BB 0%, #4967AE 74.9%);
}
.section .inner .wrap .faqList li .circle:after {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  background-image: url('../../../static/images/plus.svg');
  background-repeat: no-repeat;
  background-size: 18px 18px;
  right: 5px;
  top: 5px;
  z-index: 9;
}
.section .inner .wrap .faqList li .header {
  font-size: 18px;
}
.section .inner .wrap .faqList li .description {
  display: none;
  font-size: 15px;
  font-style: italic;
  line-height: 1.6;
  margin-top: 30px;
}
@media (max-width: 480px) {
  .section .inner .wrap .faqList li .header {
    font-size: 15px;
  }
  .section .inner .wrap .faqList li .description {
    line-height: 1.5;
  }
}
.section .inner .wrap .faqList .active .circle:after {
  background-image: url('../../../static/images/minus.svg');
}
@media (max-width: 800px) {
  .section .inner .wrap h2 {
    font-size: 22px;
  }
}
@media (max-width: 420px) {
  .section .inner .wrap h2 {
    margin-bottom: 30px;
  }
  .section .inner .wrap .faqList li .description {
    font-size: 13px;
    width: calc(100% + 40px);
  }
}
