.section {
    position: relative;
    width: 100%;
    background: #fff;

    .inner {
        height: inherit;
        box-sizing: border-box;
        padding: 6rem 3.5rem;

        .wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            max-width: 111rem;
            height: 100%;
            margin: 0 auto;

            h2 {
                font-size: 28px;
                color: #101017;
                text-align: center;
                margin-bottom: 60px;
            }

            .listImage {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .form {
                        width: 440px;
                        background: #FAFAFA;
                        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
                        border-radius: 10px;
                        overflow: hidden;

                        .header {
                            background: linear-gradient(94.51deg, #2991CF 3.96%, #4967AE 79.5%);
                            color: #fff;
                            padding: 24px;
                            font-size: 18px;
                            text-align: center;
                        }

                        .content {
                            padding: 24px 38px;
                            box-sizing: border-box;

                            form {
                                display: flex;
                                flex-direction: column;

                                .row {
                                    margin-bottom: 10px;

                                    &:last-child {
                                        text-align: center;
                                        margin: 20px auto 0;
                                    }

                                    &:nth-child(5) {
                                        margin-bottom: 20px;
                                    }

                                    input {
                                        width: 100%;
                                        height: 30px;
                                        border: none;
                                        background: #FAFAFA;
                                        border-bottom: 2px solid #D7D7D7;
                                        outline: none;
                                        &[type=checkbox] {
                                            width: auto;
                                            height: auto;
                                        }
                                        &.error {
                                            border-bottom: 2px solid #ce0909;
                                            &::placeholder {
                                                color: #ce0909;
                                            }
                                        }
                                    }

                                    p {
                                        font-size: 12px;
                                    }

                                    label {
                                        display: block;
                                        margin-bottom: 10px;
                                        input[type=checkbox] {
                                            margin-right: 5px;
                                        }
                                    }

                                    .successText {
                                        font-size: 18px;
                                        color: #67B451;
                                    }

                                    .button {
                                        display: block;
                                        width: 220px;
                                        background: #67B451;
                                        border-radius: 100px;
                                        color: #fff;
                                        padding: 14px 0;
                                        border: none;
                                        user-select: none;
                                        font-size: 16px;
                                        cursor: pointer;
                                        &:hover {
                                            opacity: .8;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media (max-width: 800px) {
                .listImage {
                    .item {
                        img {
                            width: 420px;
                            height: 360px; 
                        }

                        .form {
                            width: 380px;
                        }
                    }
                }
            }

            @media (max-width: 530px) {
                .listImage {
                    .item {
                        img {
                            width: 100%;
                            height: auto;
                        }
                        .form {
                            width: 100%;
                        }
                    }
                }
            }

            @media (max-width: 1200px) {
                .listImage {
                    flex-direction: column;

                    .item {
                        &:first-child {
                            margin-bottom: 60px;
                        }
                    }
                }
            }
        }

        @media (max-width: 530px) {
            padding-top: 0;
        }

        @media (max-width: 375px) {
            .wrap .listImage .item .form .content {
                padding: 20px;
                form .row .button {
                    width: 200px;
                    font-size: 15px;
                }
            }
        }
    }
}