.footer {
    position: relative;
    background: #2F4F66;

    .inner {
        height: inherit;
        padding: 3rem 3.5rem 0;

        .wrap {
            display: flex;
            flex-direction: column;
            max-width: 111rem;
            margin: 0 auto;
            padding: 30px 0;

            .socMedia {
                margin: 20px 0 0;
                padding: 0;
                display: flex;
                align-items: center;
                list-style-type: none;
                
                li {
                    margin-right: 15px;
                    margin-bottom: 0;
                }
            }

            .menuList {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .menu {
                    display: flex;
                    flex-direction: column;
                    list-style: none;

                    li {
                        margin-bottom: 12px;
                        font-size: 14px;
                        color: #fff;

                        &:first-child {
                            margin-bottom: 24px;
                            font-size: 16px;
                            font-weight: 500;

                            &:hover {
                                cursor: default;
                            }
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            color: #fff;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .callMessage {
                display: none;
            }

            @media (max-width: 1160px) {
                .menuList {
                    flex-direction: column;

                    .menu {
                        width: 100% !important;

                        &:first-child {
                            li {
                                &:first-child {
                                    height: unset !important;
                                    text-align: center;
                                    
                                    &:after {
                                        display: none;
                                    }

                                    a {
                                        position: relative !important;
                                        top: unset !important;
                                    }
                                }

                                &:nth-child(3),&:nth-child(4),&:nth-child(5),&:nth-child(6) {
                                    display: none;
                                }
                            }
                        }

                        &:last-child {
                            li {
                                &:not(:first-child) {
                                    text-align: left !important;
                                }
                            }
                        }

                        li {
                            position: relative;

                            &:hover {
                                cursor: pointer !important;
                            }

                            &:first-child {
                                &:after {
                                    content: '';
                                    position: absolute;
                                    width: 8px;
                                    height: 12px;
                                    top: 0;
                                    right: 0;
                                    background: url('../../../static/images/arrow.svg') no-repeat;
                                }
                            }

                            &:not(:first-child) {
                                display: none;
                            }
                        }
                    }

                    .hideItem {
                        margin-bottom: 34px;

                        li {
                            &:first-child {
                                &:after {
                                    transform: rotate(90deg);
                                }
                            }
                            &:not(:first-child) {
                                display: block;
                                padding-left: 20px;
                            }
                        }
                    }
                }

                .callMessage {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin: 20px 0 0;
                    list-style: none;

                    li {
                        margin-right: 20px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .arrowUp {
                    position: absolute;
                    width: 36px;
                    height: 36px;
                    bottom: 30px;
                    right: 20px;
                    transform: translateX(-50%);
                    display: flex;
                    justify-items: center;
                    border: 2px solid #fff;
                    background: transparent; // #2571B7
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;
                    cursor: pointer;
                
                    &::before {
                        position: absolute;
                        top: 42%;
                        left: 50%;
                        transform: translate(-50%, -40%) rotate(270deg);
                        content: '';
                        background-image: url(../../../static/images/arrow.svg);
                        background-repeat: no-repeat;
                        width: 18px;
                        height: 18px;
                        background-size: 18px 18px;
                    }
                }
            }

            @media (max-width: 338px) {
                .arrowUp {
                    right: 0;
                    bottom: 52px;
                }
            }

            h4 {
                font-size: 16px;
                font-weight: 400;
                color: #fff;
                text-align: center;
                margin-top: 40px;
            }
        }
    }
}
