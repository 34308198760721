.section {
    position: relative;
    width: 100%;
    background: #fff;

    .inner {
        height: inherit;
        box-sizing: border-box;
        padding: 6rem 3.5rem;

        .wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            max-width: 111rem;
            height: 100%;
            margin: 0 auto;

            h2 {
                font-size: 34px;
                color: #101017;
                text-align: center;
                margin-bottom: 60px;
            }

            @media (max-width: 420px) {
                h2 {
                    margin-bottom: 40px;
                }
            }

            .listImage {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    p {
                        width: 420px;
                        font-size: 18px;
                        text-align: center;
                        margin-top: 48px;
                    }
                }
            }

            @media (max-width: 800px) {
                h2 {
                    font-size: 22px;
                }
                .listImage {
                    .item {
                        img {
                            width: 420px;
                            height: 360px; 
                        }
                    }
                }
            }

            @media (max-width: 530px) {
                .listImage {
                    .item {
                        img {
                            width: 100%;
                            height: auto; 
                        }
                        p {
                            width: 100%;
                        }
                    }
                }
            }

            @media (max-width: 1200px) {
                .listImage {
                    flex-direction: column;
                    
                    .item {
                        &:first-child {
                            margin-bottom: 60px;
                        }
                    }
                }
            }
        }
    }
}