.section {
    position: relative;
    width: 100%;
    background: #fff;

    .inner {
        height: inherit;
        box-sizing: border-box;
        padding: 6rem 3.5rem;

        .wrap {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            max-width: 111rem;
            height: 100%;
            margin: 0 auto;


            .leftSide {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                h2 {
                    font-size: 34px;
                    color: #101017;
                    text-align: left;
                    margin-bottom: 30px;
                }

                p {
                    text-align: left;
                }
            }

            .rightSide {
                width: 50%;
            }

            @media (max-width: 800px) {
                .leftSide {
                    h2 {
                        font-size: 22px;
                    }
                }
                .rightSide {
                    text-align: center;
                    
                    img {
                        width: 420px;
                        height: 360px; 
                    }
                }
            }

            @media (max-width: 530px) {
                .rightSide {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        @media (max-width: 1200px) {
            .wrap {
                flex-direction: column;

                .leftSide, .rightSide {
                    width: 100%;

                    h2 {
                        text-align: center;
                    }

                    p {
                        text-align: center;
                    }
                }
                
                .leftSide {
                    margin-bottom: 40px;
                }

                .rightSide {
                    text-align: center;
                }
            }
        }
    }
}

@media (max-width: 800px) {
    .section {
        background: #F3F8FF;
    }
}