@import '../../theme/Normalize.css';
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url(../../../static/fonts/Montserrat/Montserrat-Light.ttf);
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(../../../static/fonts/Montserrat/Montserrat-Regular.ttf);
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url(../../../static/fonts/Montserrat/Montserrat-Medium.ttf);
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url(../../../static/fonts/Montserrat/Montserrat-Bold.ttf);
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url(../../../static/fonts/Montserrat/Montserrat-ExtraBold.ttf);
}
@font-face {
  font-family: 'SansRoundedW05Light';
  font-style: normal;
  font-weight: 300;
  src: url('../../../static/fonts/2.woff2') format('woff2'), url('../../../static/fonts/1.woff') format('woff');
}
html {
  font-size: 10px;
}
body {
  font-family: 'Montserrat', Verdana, sans-serif;
  font-size: small;
  font-style: normal;
  font-weight: 400;
  line-height: 1.15;
  color: #000;
  background: #fff;
}
h2,
h4,
h6 {
  font-family: 'Montserrat', Verdana, sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #000;
}
h2 {
  font-family: 'SansRoundedW05Light', Verdana, sans-serif;
  font-weight: 300;
  font-size: 34px;
}
h3 {
  font-family: 'SansRoundedW05Light', Verdana, sans-serif;
  font-weight: 300;
  font-size: 28px;
}
p {
  font-family: 'Montserrat', Verdana, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #000;
}
a {
  font-family: Montserrat, Verdana, sans-seri;
  color: #0065CC;
  text-decoration: none;
  cursor: pointer;
}
.arrowUp {
  position: fixed;
  width: 36px;
  height: 36px;
  bottom: 110px;
  right: 22px;
  transform: translateX(-50%);
  display: flex;
  justify-items: center;
  border: 2px solid #fff;
  background: #2571B7;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  cursor: pointer;
}
.arrowUp::before {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -40%) rotate(270deg);
  content: '';
  background-image: url(../../../static/images/arrow.svg);
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
}
@media (max-width: 1160px) {
  .arrowUp {
    display: none;
  }
}
@media (max-width: 530px) {
  #root {
    position: relative;
  }
  #root .arrowUp {
    position: absolute;
  }
}
@media (max-width: 480px) {
  h2 {
    font-size: 26px;
  }
}
